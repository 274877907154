    @font-face { 
      font-family: "font-regular";
      src: local("font-regular"),
      url("./fonts/Montserrat-Regular.woff2") format("woff2");
    }
    @font-face {
      font-family: "font-semibold";
      src: local("font-semibold"),
      url("./fonts/Montserrat-SemiBold.woff2") format("woff2");
    } 

    @font-face {
      font-family: "font-bold";
      src: local("font-bold"),
      url("./fonts/Montserrat-Bold.woff2") format("woff2");
    }

    @font-face {
      font-family: "font-light";
      src: local("font-light"),
      url("./fonts/Montserrat-Light.woff2") format("woff2");
      }

  .fw-6{
    font-weight: 600;
  }
  .fw-7{
    font-weight: 700;
  }
  .fw-8{
    font-weight: 800;
  }
  .notfound-button:hover {
    background-color: #808080 !important;
  }
  .font-regular {
    font-family: "font-regular";
   }
   .font-semibold {
    font-family: "font-semibold";
   }
   .font-bold {
    font-family: "font-bold";
   }
   .font-light {
    font-family: "font-light";
   }
   .fs-13 {
    font-size: 13px;
   }
   .fs-14 {
    font-size: 14px;
   }

   .branding-1 {
    color: #3e3e78;
   }
   .branding-1-hover:hover {
    color: #3e3e78 !important;
   }
   .bg-branding-1 {
    background-color: #3e3e78;
   }
   .bg-branding-1-hover:hover {
    background-color: #3e3e78 !important;
   }
   .bg-branding-1-light {
    background-color: #312645 ;
   }
   .border-branding-1 {
    border-color: #3e3e78 !important;
   }
   .branding-2 {
    color: #2b233d;
   }
   .bg-branding-2 {
    background-color: #2b233d;
   }
   .border-branding-2 {
    border-color: #2b233d !important;
   }
   .branding-4{
    color: #FFA300;
   }
   .bg-branding-4{
    background-color: #FFA300;
   }
   .branding-3 {
    color:  #e12845;
   }
   .bg-branding-3 {
    background-color: #e12845;
   }
   .border-branding-3 {
    border-color: #e12845 !important;
   }
   .site-button-primary{
    background-color: #e12845;
    color: #fff;
    font-weight:600;
   }
   .site-button-primary:hover {
    opacity: .7;
  }

  .site-button-secondary{
    background-color: #00000000;
    color: #000;
    font-weight:600;
   }
   .site-button-secondary:hover {
    opacity: .7;
    
  }

   .black-clr {
    color: #000000;
   }
   .bg-position {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   }
   .parkrun-thankyou .site-header.font-face-sh.py-4.transation-ease-in-out {
    background-color: #3e3e78;
  }
   .rounded-4 {
    border-radius: 25px !important;
    }

    .z-index-9 {
    z-index: 99999;
    }
    .modal-header .btn-close {
      position: absolute;
      right: 15px;
    }
    .fade.modal.show, .modal-backdrop.show {
      z-index: 99999;
    }

   .herosec-img {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0;
    background-image:url("./images/HeroBg.png");
   }
   .herosec-img-about{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0;
    background-image:url("./images/about-banner.jpeg");
   }
   .herosec-img-prizes{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0;
    background-image:url("./images/thank-you-banner.jpeg");
   }
   .herosec-img-support{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 0;
    background-image:url("./images/donation/register-bg.jpeg");
   }

   .yellow-strip{
    width: 100vh;
    background-color: #FFA300;
    padding: 15px 10px;
    font-size: 1.2rem;
   }


    /* Side Bar */
.sidebar {transition: 0.5s ease-in-out; opacity: 0;}.sidebar.open{ width: 100%;opacity: 1;  z-index:  1030; }

.w-0 {
  width: 0;
}
.transation-ease-in-out{
  transition: 0.5s ease-in-out;
  /* transition: 0.7s ease-in; */
}

.hide-animate{
  overflow: hidden;
  height: 1px;
  transition: 1s ;
  transition-property: height;
  transition-duration: 1s;
  opacity: 0;
}
.hide-animate-end{
  overflow: hidden;
  transition: 1s ;
  height: auto;
}



/* -----Menu hamburger--- */
.MenuButton_root {
  position: relative;
  top: 0px;
  display: block;
  width: 40px;
  height: 23px;
  right: -1px;
  
}
.MenuButton_firstLine {
  position: absolute;
  width: 33px;
  height: 3px;
  background: #ffffff;
  transition: .25s;
  right: 0;
}
.MenuButton_secondLine {
  position: absolute;
  top: 9px;
  right:0px;
  width: 33px;
  height: 3px;
  background: #ffffff;
  /* -webkit-transform: rotate(1deg); */
  /* transform: rotate(1deg); */
  transition: .25s;
}
.MenuButton_thirdLine {
  position: absolute;
  top: 18px;
  right: 0;
  width: 33px;
  height: 3px;
  background: #ffffff;
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg);
  transition: .25s;
  opacity: 1;
}
.MenuButton_rootActive {
  position: relative;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  z-index: 99999
}
.MenuButton_firstLineActive {
  top: 0px;
  right: 0;
  height: 3px;
  width: 30px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  transition: .25s;
}
.MenuButton_secondLineActive {
  right: 0;
  width: 30px;
  height: 3px;
  background-color: #fff;
  right:-140px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: .25s;
}
.MenuButton_thirdLineActive {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.top-padding-sidebar{
  top: 110px !important;
}

  
/* -----Menu hamburger--- */
#dropdown-menu-align-end.dropdown-toggle.btn.btn-primary {
  background-color: transparent !important;
  border: 0px !important;
}

/* Accordian */
.accordion-button {
  justify-content: center !important;
  background-color: #3e3e78 !important;
  color: #fff !important;
  padding: 25px !important;
  font-size: 20px;
}
.accordion-button::after, .accordion-button::after {
  right: 16px;
  position: absolute;
}


.slick-next::before, .slick-prev::before {
  color: #3e3e78;
}
.footer h2 {
  color: #b4b4b4;
}
.footer .footer-main p {
  color: #767676;
  line-height: 26px;
}

.form-group .form-control-password-wrap::after {
  content: url("/src/images/lock.svg");
  position: absolute;
  right: 10px;
  top: 15px;
  width:20px;
}
.form-group .form-control-email-wrap::after {
  content: url("/src/images/email_icon.svg");
  position: absolute;
  right: 10px;
  top: 15px;
  width:20px;
}


/* Donation Page */

#monthly-tab {
  border-radius: 0px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: #2b233d;
}
#annually-tab {
  border-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #2b233d;
}
#annually-tab, #monthly-tab {
  border: 1px solid #b4b4b4;
}
#annually-tab.active, #monthly-tab.active {
  background-color: #ffa300;
  border: 1px solid #ffac00 !important;
}
#annually-tab.active, #monthly-tab.active {
  background-color: #ffa300;
  border: 1px solid #ffac00 !important;
  color: #fff !important;
}
.donation .nav-link {
  border: 1px solid #2b233d; 
}
.plan-benefits{
  list-style-image: url('/src/images/donation/tick.svg');
}


.online-post-tab .nav-link.active {
  background-color: #2b233d;
  border-radius: 0;
  color: #fff;
}
.online-post-tab .nav-link {
  border-radius: 0;
  border: 1px solid #2b233d;
  color: #2b233d;
}


/* terms and Conditions */
.tc p,.tc span,.tc ul{
  font-family: "font-light";
  color : #b4b4b4;
}

ol {
  list-style: decimal;
  color: #b4b4b4;
}

/* Account page */
.account-nav-recdonation a.btn.active {
  color: #ffa300;
  background-color: #000000;
  border: none;
}
.account-nav-recdonation a.btn {
  font-size: 14px;
  height: 48px;
  line-height: 49px;
  background-color: transparent;
}
ul.plan-benefits li {
  line-height: 2;
}
.btn-cancel {
  height: 66px;
  background-color: #fcfcfc;
  border-radius: 10px;
  border: 2px solid #767676;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  padding-top: 21px;
  color: #363636 !important;
}
.btn {
  padding: 0;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  opacity: 1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.btn:active, .btn:hover,.btn-cancel:hover {
  opacity: .7;
}
.btn.btn-details {
  color: #fff;
  background-color: #3e3e78;
  width: 130px;
}
.btn.btn-details:hover {
  color: #fff;
  background-color: #3e3e78;
  opacity: .7;
}
.dark-grey {
  color: #767676 !important;
}
.card-block.card-my-details a {
  color: #6c6c96;
}
.control {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: .85rem;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.consent-check {
  z-index: 999;
}
.control_indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background: #fcfcfc;
  border: 1px solid #bdbfc3;
  border-radius: 5px;
}
.control:hover input:not([disabled]):checked~.control_indicator, .control input:checked:focus~.control_indicator, .control input:checked~.control_indicator {
  background: #fff;
}
.control input:checked~.control_indicator:after {
  display: block;
}

.control-checkbox input.checked~.control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 10px;
  top: 3px;
  width: 7px;
  height: 15px;
  border: solid #00ceae;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control.edit-checkbox {
  padding-left: 40px;
}
.form-group .form-control-wrap input {
  height: 54px!important;
  background-color: #fcfcfc;
}
.form-group .form-control-wrap input, .form-group select {
  font-size: 14px;
  line-height: 14px;
  color: #363636;
}
.form-group {
  margin-bottom: 12px;
}
button.edit-btn {
  background-color: transparent !important;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #363636 !important;
    text-decoration: underline;
    font-weight: normal;
    height: auto;
}

#dropdown-menu-align-end.dropdown-toggle.btn.btn-primary {
  background-color: transparent !important;
  border: 0px !important;
  font-family: "font-regular";
}
.dropdown-menu.show {
  width: 219px;
  -webkit-box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
-moz-box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
border-top: 1px solid #f1f1f1;
}
.dropdown-menu.show.dropdown-menu-end a {
  padding: 10px 20px;
  width: 100%;
  font-family: "font-regular";
  color: #3e3e78;
}
.Toastify {
  z-index: 9999999;
  position: relative;
}

.PhoneInputInput, .PhoneInputInput:focus-visible{
  border: none !important;
  outline: none !important;
  background-color: #fff;
}

@media only screen and (max-width: 1500px) {
  .herosec-img{
    height:120vh !important;
  }


}

@media only screen and (max-width: 991px) {
  .herosec-img{
    height:120vh !important;
  }
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: flex !important;
  }
  .site-header {
    /* background-color: #3e3e78; */
    z-index: 99999;
  position: relative;
  }

  .recdonation-account-banner .col-12.col-md-8.offset-md-2.col-lg-6.offset-lg-3.mb-5 {
    width: 100%;
    margin: 0 auto;
   }
   .recdonation-account-banner .card.content-card.account-support-card.bg-branding-1 {
    max-width: 90% !important;
  }
  .page.account .noSpaceLeftResp {
    margin-left: 0px !important;
    width: 100%;
  }
  .page.account .noSpaceLeftResp .your-entries {
    width: 90% !important;
    max-width: 90% !important;
  }
  }
  
  @media only screen and (max-width: 767px) {
    .notfound {
      width: 100% !important;
    }
    .notfound h1 {
      font-size: 40px !important;
    }
    .notfound-page {
      padding: 30px 0px !important;
    }
    .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
      width: 50%;
      margin: 0 auto;
  }
  .logo-thank-you .share-icons.w-50 {
    width: 30% !important;
  }
  .col-md-8.align-middle.pb-md-0.pb-4.font-face-gb.thank-you-title {
    text-align: center;
    margin-bottom: 30px;
  }
  .container-fluid.thank-home.home-left-side.mb-md-0 {
    padding-top: 500px !important;
    padding-bottom: 40px !important;
  }
  .thankyou-header {
    background-size: unset !important;
  }


    .herosec-img{
      height:130vh !important;
    }
    .hide_responsive{
      display: none;
    }
    .wt-100 {
      width: 100% !important;
    }
    .matchcard-logosblock {
      padding: 30px 15px !important;
    }
    .match-card {
      margin: 0 !important;
    }
    .matchcard-header img {
      width: 140px !important;
    }
    .desktop-btn {
      display: none;
    }
    .mobile-bottom {
      display: block !important;
    }
    .mobile-bottom button {
      width: 100% !important;
    }
    .hero-section {
      padding-bottom: 130px !important;
    }
    .stories-section-title {
      margin-top: 0px !important;
    }
    .stories-container {
      width: 100% !important;
    }
    .stories-container {
      width: 100% !important;
      border: none;
      padding: 0 !important;
      text-align: center;
    }
    .story-block-stats .d-flex.align-items-center {
      justify-content: center;
    }
    .story-block-testimonial h4 {
      margin-top: 30px;
    }
    .story-photo img {
      width: 108px !important;
      margin: 0 auto;
    }
    .curve-dot {
      bottom: -39px !important;
    }
    .story-block-inner {
      padding-bottom: 95px !important;
    }
    .slick-dots {
      bottom: -60px;
    }
    .prizes-section_container {
      width: 89% !important;
    }
    .prizes-section_blockcontainer_sec {
      margin-left: -24px;
      margin-right: -24px;
    }
    .prizes-section-prizes_container {
      margin-left: -24px !important;
      margin-right: -24px !important;
      margin-top: 20px !important;
    }
    .prizes-section-prizes_container h3 {
      font-size: 20px !important;
    }
    .prizes-section-prizes_container .col-md-6 {
      width: 50%;
    }
    .how-it-works_section .col-md-4 P {
      margin-top: 30px !important;
      margin-bottom: 40px;
    }
    .container-2 {
      max-width: 89% !important;
      margin: 0 auto;
    }
    .charity_section p.m-auto {
      margin: 0 !important;
      width: 100% !important;
      margin-top: 20px !important;
    }
    .charity_section .col-sm-12.col-md-12.col-lg-6.pe-5 {
      padding-right: 12px !important;
    }
    .charity_section button {
      width: 100% !important;
      margin-top: 30px !important;
      margin-bottom: 50px !important;
    }
    .bg-branding-green {
      width: 100% !important;
    }
    .container {
      padding: 0px 20px;
    }
    .prizes-section::before {
      width: 100vw;
      height: 97%;
    }
    .wt-100 {
      width: 100% !important;
    }
    .wt-95 {
      width: 95% !important;
    }
    .home-video iframe {
      height: 190px;
    }
    /*Card Payment Method*/
  .payment-card-modal-header.modal-header {
    right: 0;
    /* padding-top: 0; */
  }
  .payment-card-modal-header.modal-header .modal-title div {
    padding: 0 !important;
  }
  .payment-card-modal-body.modal-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.pe-2 {
    padding-right: 4px !important;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.ps-2 {
    padding-left: 4px !important;
  }
  .card-brand-logo {
    width: 50px !important;
    top: -5px !important;
  }
  .card-brand-logo-type {
    width: 40px !important;
  }
  .payment-card-number {
    font-size: 18px !important;
  }
  .payment-card-holdername {
    font-size: 18px !important;
  }
  .make-default-btn {
    width: 50px !important;
  }
    }

    @media only screen and (max-width: 500px) {
      .herosec-img{
        height:120vh !important;
      }
      .bottom-slider{
        margin-top: -85px !important;
      }
      .bottom-slider .responsive-margin{
        margin-top: 50px;
      }
      .bottom-slider .image-container{
        top: -50px !important;
        left: 0px !important;
        right: 0px !important;
        width: 100px ;
        margin: auto ;
      }
      .top-text{
        padding-top: 50px;
      }
      .slider-container{
        margin:0px !important;
      }
      .recdonation-account-banner .card.content-card.account-support-card.bg-branding-1 {
        max-width: 100% !important;
      }
      .page.account .noSpaceLeftResp .your-entries {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    @media only screen and (max-width: 480px) {
      .yellow-strip.text-center.w-100.m-0.p-2 .col-12.p-5 {
        padding: 40px 0px !important;
      }
      .notfound a {
        width: 40% !important;
        margin: 20px 10px !important;
        font-size: 16px !important;
      }
      .notfound h1 {
        font-size: 28px !important;
      }
      .notfound h5 {
        font-size: 18px !important;
      }
      .footer-section::after {
        background-size: cover;
        height: 100%;
      }
      .notfound img {
        width: 80% !important;
      }
      .control {
        bottom: 4px !important;
      }

      .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
        width: 100%;
        margin: 0 auto;
      }
      .logo-thank-you .share-icons.w-50 {
        width: 70% !important;
      }
      .container-fluid.home-left-side.mb-md-0.position-relative.thankyou-header {
        padding-top: 20px !important;
      }
      .container-fluid.thank-home.home-left-side.mb-md-0 {
        padding-top: 450px !important;
        padding-bottom: 40px !important;
      }
      .btn.thankyou-page-button.text-uppercase.bg-branding-1.border-0.rounded-pill.py-3.px-5.lh-1.text-light {
        padding: 20px !important;
      }
      .footer-section::after {
        background-size: cover;
      }
      .herosec-img{
        height:130vh !important;
      }
      .wt100Resp {
        width: 100% !important;
      }
      .account-nav-recdonation .d-flex.flex-row.justify-content-center.flex-wrap {
        display: block !important;
        text-align: center;
      }
      .select-donate-btn {
        font-size: 14px !important;
      }
      .payment-card-modal-footer.modal-footer .card-foot-inner {
        margin: 0;
        padding-top: 5px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .payment-card-modal-footer.modal-footer .card-foot-inner .btn {
        width: 50%;
      }
      .payment-card-modal-footer.modal-footer .card-foot-inner .row div {
        padding: 0;
      }
    }

    /* .thankyou-page-button:hover {
      background-color: #000 !important;
      opacity: 1;
    } */